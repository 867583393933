import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import { DateTime } from "luxon";
import api from "../../services/api";

import './EventSchedule.scss';

class EventSchedules extends React.Component {
  state = {
    schedule: [[]],
    date: [],
    day: [
      "Primeiro dia",
      "Segundo dia",
      "Terceiro dia",
      "Quarto dia",
      "Quinto dia",
      "Sexto dia",
      "Sétimo dia",
      "Oitavo dia",
      "Nono dia",
      "Decimo dia",
    ],
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  };

  componentDidMount() {
    const getSchedule = async () => {
      const res = await api.post(
        "/database/auditorio_master_feira__c/aggregate",
        [
          { $match: { position: "adminHotSite" } }, 
          { $sort: { startTime: 1, order: -1 } }
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                window.INOVA_PRESETS.APP_APIKEY +
                ":" +
                window.INOVA_PRESETS.APP_PUBLIC_SECRETKEY
              ),
          },
        }
      );

      let data = [];
      const temp = [];
      const date = [];

      res.data.map((item) => {
        let day = null;
        const dateParser = DateTime.fromISO(
          new Date(item.startTime).toISOString(),
          { zone: "America/Sao_Paulo" }
        );
        temp.forEach((i) => {
          if (i === dateParser.toFormat("d")) {
            day = dateParser.toFormat("d");
          }
        });

        if (!day) {
          temp.push(dateParser.toFormat("d"));
          date.push(dateParser);
        }

        if (!data[temp.indexOf(dateParser.toFormat("d"))]) {
          data[temp.indexOf(dateParser.toFormat("d"))] = [];
        }

        data[temp.indexOf(dateParser.toFormat("d"))].push(item);

        return (item.startTime = dateParser);
      });

      this.setState({ schedule: data });
      this.setState({ date: date });
    };

    getSchedule();
  }

  render() {
    return (
      <section id="schedule" className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="section-title">
            <span>Reserve na sua agenda</span>
            <h2>Programação</h2>

            <LaxDiv text="Programação" dataPreset="driftLeft" />

            {/* <Link to="#" className="btn btn-primary">Buy Tickets Now!</Link> */}

            <div className="bar"></div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  {this.state.date.map((item, index) => (
                    <li
                      key={item}
                      onClick={(e) => this.openTabSection(e, "tab" + index + 1)}
                      className={index === 0 ? "current" : ""}
                    >
                      <div>
                        {this.state.day[index]}
                        {/* <span>12 Abril 2021</span> */}
                        <span>
                          {item.setLocale("pt-br").toFormat("d MMMM y")}
                        </span>
                      </div>
                    </li>
                  ))}

                  {/* <li onClick={(e) => this.openTabSection(e, "tab2")}>
                    <div>
                      Segundo dia
                      <span>13 Abril 2021</span>
                    </div>
                  </li> */}
                </ul>

                <div className="tab_content">
                  {this.state.schedule.map((i, index) => (
                    <div
                      key={index}
                      id={"tab" + index + 1}
                      className="tabs_item"
                    >
                      <ul className="accordion">
                        {i.map((item) => (
                          <li key={item._id} className="accordion-item">
                            <Link className="accordion-title" to="#">
                              {/* <div className="author">
                                <img
                                  src={require("../../assets/images/author1.jpg")}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Steven Smith"
                                  alt="Author"
                                />
                              </div> */}

                              <div className="schedule-info">
                                <span className="init-time">
                                  {item.hour}
                                  {/* - {item.endHour} */}
                                </span>
                                <h3>{item.title}</h3>
                                  <h5 dangerouslySetInnerHTML={{__html: item.description}} />
                                <ul>
                                  {/* <li>
                                    <i className="icofont-calendar"></i>
                                    {item.startTime
                                      .setLocale("pt-br")
                                      .toFormat("dd/MM/yyyy")}
                                  </li> */}
                                  {/* <li>
                                    <i className="icofont-wall-clock"></i>
                                    {item.hour} - {item.endHour}
                                  </li> */}
                                  {item.presenters.length > 0 && (
                                    <>
                                      <br />
                                      <br />
                                      <li>
                                        {/* <i className="icofont-user-suited"></i>{" "}
                                        Palestrante
                                        <br />
                                        <br /> */}
                                        {item.presenters.map((item, index) => (
                                          <>
                                            <div key={index} className="presenter-info">
                                              {item.image &&
                                                <span className="avatar">
                                                  <img src={item.image} alt={item.name} />
                                                </span>
                                              }
                                              <div>
                                                <h4>{item.name}</h4>
                                                {item.resume && (
                                                  <p>{item.resume}</p>
                                                )}
                                              </div>
                                            </div>{" "}
                                            <br />
                                          </>
                                        ))}
                                      </li>
                                    </>
                                  )}
                                </ul>
                                <br />
                              </div>
                              <div className="schedule-info"></div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12">
              <div className="btn-box">
                <Link to="#" className="btn btn-primary">
                  Download Schedule (PDF)
                </Link>
                <Link to="#" className="btn btn-secondary">
                  Connect Via Instagram
                </Link>
              </div>
            </div> */}
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    );
  }
}

export default EventSchedules;
