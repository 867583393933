import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import OwlCarousel from "react-owl-carousel3";
import api from "../../services/api";

const options = {
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 3,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

class Partner extends React.Component {
  state = {
    sponsors: [],
    typeSponsor: [],
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  componentDidMount() {
    const getSponsors = async () => {
      const response = await api.post(
        "/database/sponsors__c/aggregate",
        [
          { $group: { _id: "$typeSponsor", order: { $addToSet: "$order" } } },
          { $sort: { order: 1 } },
          {
            $lookup: {
              from: "sponsors__c",
              localField: "order",
              foreignField: "order",
              as: "sponsors",
            },
          },
          { $project: { _id: 1, sponsors: 1 } },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                window.INOVA_PRESETS.APP_APIKEY +
                  ":" +
                  window.INOVA_PRESETS.APP_PUBLIC_SECRETKEY
              ),
          },
        }
      );

      this.setState({ sponsors: response.data });
    };

    getSponsors();
  }

  render() {
    return (
      <section
        id="sponsors"
        className="partner-area ptb-120"
        style={{ display: "block !important" }}
      >
        <div className="container">
          <div className="section-title">
            <span>Empresas presentes</span>
            <h2>Patrocinadores</h2>

            <a href="#contact" className="btn btn-primary">
              Seja um patrocinador
            </a>

            <div className="bar"></div>
          </div>

          <div className="row">
            {this.state.sponsors &&
              this.state.sponsors[0] &&
              this.state.sponsors.map((item, index) => (
                <React.Fragment key={item._id}>
                  <div className="col-lg-12">
                    <div
                      className={`partner-title  ${
                        index % 2 === 0 ? "platinum-sponsor" : "gold-sponsor"
                      }`}
                    >
                      <h3 className="lax" data-lax-preset="driftRight">
                        {item._id}
                      </h3>
                    </div>
                  </div>
                  <OwlCarousel
                    className={`platinum-partner-slides owl-carousel owl-theme ${item.sponsors.length >= 5 ? '' : 'center-carousel'}`}
                    {...options}
                    loop={item.sponsors.length >= 5}
                  >
                    {item.sponsors.map((sponsor) => (
                      <div key={sponsor._id} className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <Link to="#">
                            <img src={sponsor.image} alt="Partner Logo" />
                            <img src={sponsor.image} alt="Partner Logo" />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>

                  {index + 1 < this.state.sponsors.length && (
                    <div className="col-lg-12">
                      <div className="border"></div>
                    </div>
                  )}
                </React.Fragment>
              ))}

            {/* <div className="col-lg-12">
              <div className="border"></div>
            </div>

            <div className="col-lg-12">
              <div className="partner-title gold-sponsor">
                <h3 className="lax" data-lax-preset="driftLeft">
                  {this.state.sponsors[1] &&
                    this.state.sponsors[1][0] &&
                    this.state.sponsors[1][0].typeSponsor}
                </h3>
              </div>
            </div> */}

            {/* <OwlCarousel
              className="gold-partner-slides owl-carousel owl-theme"
              {...options}
              loop={this.state.sponsors[1].length >= 5}
            >
              {this.state.sponsors[1].map((item) => (
                <div key={item._id} className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#">
                      <img src={item.image} alt="Partner Logo" />
                      <img src={item.image} alt="Partner Logo" />
                    </Link>
                  </div>
                </div>
              ))}
              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#">
                    <img
                      src={require("../../assets/images/gold-partner1.png")}
                      alt="Partner Logo"
                    />
                    <img
                      src={require("../../assets/images/gold-partner1.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#">
                    <img
                      src={require("../../assets/images/gold-partner2.png")}
                      alt="Partner Logo"
                    />
                    <img
                      src={require("../../assets/images/gold-partner2.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#">
                    <img
                      src={require("../../assets/images/gold-partner3.png")}
                      alt="Partner Logo"
                    />
                    <img
                      src={require("../../assets/images/gold-partner3.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#">
                    <img
                      src={require("../../assets/images/gold-partner4.png")}
                      alt="Partner Logo"
                    />
                    <img
                      src={require("../../assets/images/gold-partner4.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#">
                    <img
                      src={require("../../assets/images/gold-partner5.png")}
                      alt="Partner Logo"
                    />
                    <img
                      src={require("../../assets/images/gold-partner5.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>
            </OwlCarousel> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Partner;
